import { createApp } from 'vue'
import App from './App.vue'
import createAppRouter from './router'

const initApp = async () => {
    const router = await createAppRouter();
    const app = createApp(App);
    app.use(router);
    app.mount('#app');
};

initApp().catch(error => {
    console.error('Failed to initialize app:', error);
});