import { useRoute } from 'vue-router';

export function useURLNavigation() {
    const route = useRoute();
    
    function parseURL(portData) {
        const pathSegments = route.path.split('/').filter(Boolean);
        console.log('useURLNavigation parseURL pathSegments', pathSegments);

        let marketKey;
        let sectionKey;
        let projectKey;
    
        if (!pathSegments[0] || !Object.keys(portData.markets).includes(pathSegments[0])) {
            marketKey = Object.keys(portData.markets).find(key => portData.markets[key].default === true) || 'default';
        } else {
            marketKey = pathSegments[0];
        }
    
        if (!pathSegments[1] || !Object.keys(portData.sections).includes(pathSegments[1])) {
            sectionKey = Object.keys(portData.sections).find(key => portData.sections[key].default === true) || portData.sections[0];
        } else {
            sectionKey = pathSegments[1];
        }
        
        // TODO: We might want to make a project not found page to send this to
        projectKey = Object.keys(portData.projects).includes(pathSegments[2]) ? pathSegments[2] : null;
        
        return {
            marketId: marketKey,
            sectionId: sectionKey, 
            projectId: projectKey
            }
        }
    
    return {
        parseURL
    }

}