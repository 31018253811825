import { createRouter, createWebHistory } from 'vue-router';

// Get the base URL for the current environment
const getBaseUrl = () => {
    // Remove trailing slash if present
    const pathname = window.location.pathname.replace(/\/$/, '');
    // Get the first directory in the path if it exists
    const firstDir = pathname.split('/')[1];
    // If we're in production and there's a subdirectory, use it as base
    if (process.env.NODE_ENV === 'production' && firstDir) {
        return `/${firstDir}/`;
    }
    return '/';
};

// Fetch portfolio data function
const fetchPortfolioData = async () => {
    try {
        const baseUrl = getBaseUrl();
        // Remove leading slash from json/portfolio.json since baseUrl includes trailing slash
        const response = await fetch(`${baseUrl}json/portfolio.json`);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching portfolio data:', error);
        throw error;
    }
};

const loadSectionPath = (marketRoute, section) => {
    let cleanRoute = marketRoute.startsWith('/') ? marketRoute.slice(1) : marketRoute;
    cleanRoute = cleanRoute.endsWith('/') ? cleanRoute.slice(0, -1) : cleanRoute;
    
    const fullPath = section.routerPath.startsWith('/')
        ? '/' + cleanRoute + section.routerPath
        : '/' + cleanRoute + '/' + section.routerPath;

    // Don't include base URL in path - router will handle that
    return fullPath;
};

/**
 * Retrieves the display name from a section object with fallback handling
 * @param {Object} section - The section object to get the name from
 * @param {Object} options - Optional configuration
 * @param {string} options.fallback - Fallback text if no name is found
 * @param {boolean} options.trim - Whether to trim the resulting name
 * @returns {string} The formatted display name
 * @throws {Error} If section parameter is null or undefined
 */
const loadName = (section, options = {}) => {
    // Input validation
    if (!section) {
        throw new Error('Section parameter is required');
    }

    const {
        fallback = 'David Moreau Design',
        trim = true
    } = options;

    // Try different name properties in order of preference
    const name = section.displayName || 
                 section.name ||
                 section.title ||
                 fallback;

    // Format the name
    const formattedName = trim ? name.trim() : name;

    // Ensure we're not returning empty string
    return formattedName || fallback;
};

const loadComponent = (section) => {
    let component;
    if (Array.isArray(section.component)) {
        component = section.component[0];
    } else if (typeof section.component === 'string') {
        component = section.component
    } else {
        console.error("Router could not parse section.component")
    }

    // console.log("router loadComponent component", component);

    return () => import(`@/components/${component}.vue`);
};

const loadMeta = (section) => {
    if (section.component.includes("CircleGridGallery")) {
        return {
            title: section.title,
            extended: true
        }
    }
    else {
        return {
            title: section.title,
            extended: false
        }
    }
}

const loadProps = (section, portData) => {
    function processCircleGridGallery() {
        // Input validation
        if (!section || !portData) {
            throw new Error('Section and portfolio data are required');
        }
    
        const galleries = Object.values(section.galleries ?? {});
    
        const projectList = galleries.flatMap(gallery => {
            // Get projects from gallery, defaulting to empty object if undefined
            const projects = gallery.projects ?? {};
            
            return Object.entries(projects).map(([projectKey, projectDetails]) => {
                const projectData = portData.projects?.[projectKey];
                
                if (!projectData) {
                    console.warn(`Project not found: ${projectKey}`);
                    return null;
                }
    
                return {
                    ...structuredClone(projectData),
                    key: projectKey,
                    sectionsToShow: projectDetails.sectionList
                };
            });
        }).filter(Boolean); // Remove null entries from missing projects
    
        return {
            name: section.displayName ?? 'Unnamed Section',
            projectData: projectList
        };
    }    // console.log("loadProps marketKey", marketKey);

    try {
        let component;
        if (Array.isArray(section.component)) {
            component = section.component[0];
        } else if (typeof section.component === 'string') {
            component = section.component
        } else {
            console.error("Router could not parse section.component")
        }

        // console.log("router loadProps component", section.component);
        switch (component) {
            case "CircleGridGallery": {
                const propObj = processCircleGridGallery();
                return propObj;
            }
            case "ScrollGallerySingleImage":
                return {
                    name: typeof section.displayName === 'string' ? section.displayName : '',
                    galleriesData: typeof section.galleries === 'object' && section.galleries !== null ? section.galleries : {}                
                };
            case "AboutMe":
                // console.log("router AboutMe aboutType");
                return {
                    name: typeof section.displayName === 'string' ? section.displayName : '',
                    componentProps: typeof section.componentProps === 'object' && section.componentProps !== null ? section.componentProps : {}
                }
            default:
                return {
                    name: typeof section.displayName === 'string' ? section.displayName : '',
                    galleriesData: typeof section.galleries === 'object' && section.galleries !== null ? section.galleries : {},          
                    componentProps: typeof section.componentProps === 'object' && section.componentProps !== null ? section.componentProps : {}
                };
        }
    }
    catch (error) {
        console.error(`Error in loadProps for section ${section.name}:`, error);
        return;
    }
}

const parseURL = (portData) => {
    const baseUrl = getBaseUrl();
    // Remove base URL from pathname before parsing
    const pathWithoutBase = window.location.pathname.replace(baseUrl, '');
    const pathSegments = pathWithoutBase.split('/').filter(Boolean);
    console.log('router.js parseURL pathSegments', pathSegments);

    let marketKey;
    let sectionKey;
    let projectKey;

    if (!pathSegments[0] || !Object.keys(portData.markets).includes(pathSegments[0])) {
        marketKey = Object.keys(portData.markets).find(key => portData.markets[key].default === true) || 'default';
    } else {
        marketKey = pathSegments[0];
    }

    if (!pathSegments[1] || !Object.keys(portData.sections).includes(pathSegments[1])) {
        sectionKey = Object.keys(portData.sections).find(key => portData.sections[key].default === true) || portData.sections[0];
    } else {
        sectionKey = pathSegments[1];
    }
    
    projectKey = Object.keys(portData.projects).includes(pathSegments[2]) ? pathSegments[2] : null;
    
    return {
        marketId: marketKey,
        sectionId: sectionKey, 
        projectId: projectKey
    };
};

const getHomePage = (marketKey, portData) => {
    
    console.log('router.js getHomePage marketKey', marketKey);

    let [rKey, rSection] = Object.entries(portData.sections).find(([, section]) => section.default === true) || Object.entries(portData.sections)[0];
    const navList = Object.values(portData.markets[marketKey]?.sections || portData.markets['default']?.sections);

    console.log('router.js getHomePage rkey', rKey);
    console.log('router.js getHomePage rSection', rSection);
    console.log('router.js getHomePage navList', navList);
    
    if (!navList.includes(rKey)) {
        rSection = portData.sections[navList[0]];
    }
    
    if (!rSection) {
        console.error("No Home Page Found")
    }

    return rSection;
};

// Create an async router factory
const createAppRouter = async () => {
    // Fetch portfolio data
    const portData = await fetchPortfolioData();
    const sections = Object.values(portData.sections || {});
    const parsedURL = parseURL(portData)
    const rootPageSection = getHomePage(parsedURL.marketId, portData);

    // Debug logs
    if (process.env.NODE_ENV === 'production') {
        console.log('Production mode detected');
        console.log('Base URL:', getBaseUrl());
        console.log('Current pathname:', window.location.pathname);
    }

    const routes = [
        {
            path: '/',
            redirect: () => {
                return { path: loadSectionPath(parsedURL.marketId, rootPageSection) }
            }
        },
        {
            path: '/' + parsedURL.marketId,
            redirect: () => {
                return { path: loadSectionPath(parsedURL.marketId, rootPageSection) }
            }
        },
        ...sections
            .filter(section => section.routerPath !== '/')
            .map(section => {
                const baseRoute = {
                    path: loadSectionPath(parsedURL.marketId, section),
                    name: loadName(section),
                    component: loadComponent(section),
                    meta: loadMeta(section),
                    props: () => ({
                        ...loadProps(section, portData),
                    })
                };

                // Add nested route for CircleGridGallery
                if (Array.isArray(section.component) ?
                    section.component[0] === "CircleGridGallery" :
                    section.component === "CircleGridGallery") {
                    baseRoute.children = [{
                        path: ':projectId',
                        name: `${loadName(section)}-project`,
                        component: loadComponent(section),
                        props: () => ({
                            ...loadProps(section, portData)
                        })
                    }];
                }

                return baseRoute;
            }),
        {
            path: '/:pathMatch(.*)*',
            name: 'NotFound',
            component: () => import('@/components/NotFound.vue'),
            meta: { title: "Nothing Quite Sounds Like A . . ." },
        }
    ];
    
    const router = createRouter({
        history: createWebHistory(getBaseUrl()),
        routes
    });

    // navigation debugging in production
    if (process.env.NODE_ENV === 'production') {
        router.beforeEach((to, from) => {
            console.log('Navigation:', {
                to: to.fullPath,
                from: from.fullPath,
                base: router.options.history.base,
                currentUrl: window.location.href
            });
        });
    }

    // Set Document Title
    router.beforeEach((to) => {
        if (to.meta && to.meta.title) {
            document.title = to.meta.title 
        } else {
            document.title = "David Moreau Design"
        }
    });

    return router;
};

export default createAppRouter;