import { ref, computed } from 'vue'
import colors from "@/assets/json/portfolioColors.json"

// Modular Sizes
export function useModularScale(initialMultiplier = 1.414, initialBaseSize = 20) {
  const multiplier = ref(initialMultiplier);
  const baseSize = ref(initialBaseSize);

  const scale = computed(() => ({
    text00000: Math.round(baseSize.value * Math.pow(multiplier.value, -4)),
    text0000: Math.round(baseSize.value * Math.pow(multiplier.value, -3)),
    text000: Math.round(baseSize.value * Math.pow(multiplier.value, -2)),
    text00: Math.round(baseSize.value * Math.pow(multiplier.value, -1)),
    text0: Math.round(baseSize.value),
    text1: Math.round(baseSize.value * Math.pow(multiplier.value, 1)),
    text2: Math.round(baseSize.value * Math.pow(multiplier.value, 2)),
    text3: Math.round(baseSize.value * Math.pow(multiplier.value, 3)),
    text4: Math.round(baseSize.value * Math.pow(multiplier.value, 4)),
    text5: Math.round(baseSize.value * Math.pow(multiplier.value, 5)),
    text6: Math.round(baseSize.value * Math.pow(multiplier.value, 6)),
    text7: Math.round(baseSize.value * Math.pow(multiplier.value, 7)),
  }))

  const setModularMultiplier = (value) => {
    // console.log("Setting Modular Multiplier To:", value);
    multiplier.value = value
  }

  const setModularBaseSize = (value) => {
    // console.log("Setting Modular Base Size To:", value);
    baseSize.value = value
  }

  const checkScaleValues = () => {
    console.log('Text Scale values:')
    Object.entries(scale).forEach(([key, value]) => {
      console.log(`${key}: ${value}`)
    })
  }

  return {
    multiplier,
    baseSize,
    scale,
    setModularMultiplier: setModularMultiplier,
    setModularBaseSize: setModularBaseSize,
    checkScaleValues,
  }
}

// Semantic Sizes
export function useSemanticSizes() {
  const navMenuWidth = ref(24);

  const setnavMenuWidth = (value) => {
    navMenuWidth.value = value;
  }
  
  return {
    navMenuWidth,
    setnavMenuWidth
  }
}

// Colors
export function useSemanticColors() {
  // console.log("Base Colors", colors)

    const bgColors = computed(() => ({
        light: colors.neutral["1000"],
        lightTranslucent: colors.neutral["1000-70"],
        mediumTranslucent: colors.neutral["900-70"],
        dark: colors.neutral["000"],
        accent: colors.accent01["000"],
        accentTranslucent: colors.accent01["000-70"]
    }))

    const textColors = computed(() => ({
        bgLight: colors.neutral["000"],
        bgLightFaint: colors.neutral["800"],
        bgDark: colors.neutral["1000"],
        accent: colors.accent01["000"]
    }))

    const buttonColors = computed(() => ({
        neutralDark: colors.neutral["000"],
        neutralMedium: colors.neutral["900"],
        neutralLight: colors.neutral["1000"],
        hover: colors.accent02["000"],
        selected: colors.accent01["000"],
        accent: colors.accent01["000"],
        accentTranslucent: colors.accent01["000-70"]
    }))


    return {
        bgColors,
        textColors,
        buttonColors
    }
}